<mat-card-header>
  <mat-card-title>
    Organizações
  </mat-card-title>
  <div fxFlex></div>
  <mat-form-field>
    <mat-label>Filtrar Organizações</mat-label>
    <input matInput type="text" (keyup)="filterGroups($event.target.value)">
  </mat-form-field>
  <div *ngIf="isActiveGroupsTable">
    <button mat-button class="example-icon" (click)="openNewOrgDialog()">
      <mat-icon>add</mat-icon>
      Criar organização
    </button>
  </div>
</mat-card-header>
<mat-card-content>
  <table mat-table [dataSource]="dataSource" matSort matSortActive="feature_plan_end_date" matSortDirection="asc" class="full-width">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="name"> Nome </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="test_plan">
      <th mat-header-cell mat-sort-header="test_plan" *matHeaderCellDef> Em Teste </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.info">
          <span class="badge badge-pill {{element.info.test_plan ? 'badge-success' : 'badge-danger'}}">
            {{element.info.test_plan ? "Sim" : "Não"}}
          </span>
        </div>
        <div *ngIf="!element.info">-</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="active">
      <th mat-header-cell mat-sort-header="active" *matHeaderCellDef> Ativa</th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.info">
          <span class="badge badge-pill {{element.info.active ? 'badge-success' : 'badge-danger'}}">
            {{element.info.active ? "Sim" : "Não"}}
          </span>
        </div>
        <div *ngIf="!element.info">
          <span class="badge badge-pill badge-danger">Não</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="feature_plan_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="feature_plan_name"> Plano </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.info">
          {{element.info.feature_plan_name}}
        </div>
        <div *ngIf="!element.info">-</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="feature_plan_end_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="feature_plan_end_date">
        Fim do Contrato
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="feature-plan-end-date-column">
          <div *ngIf="element.info">
            {{element.info.feature_plan_end_date|date:'dd/MM/yyyy'}}
          </div>
          <div *ngIf="!element.info">-</div>
          <mat-icon *ngIf="hasFeaturePlanEndDateWarning(element)"
                    style="margin-left: 8px"
                    class="{{element.info?.active ? 'yellow-icon' : ''}}"
                    color="warn"
                    matTooltip="{{getTooltipMessage(element)}}">
            timelapse
          </mat-icon>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="automatic_renovation">
      <th mat-header-cell mat-sort-header="automatic_renovation" *matHeaderCellDef>
        Renovação Automática
      </th>
      <td mat-cell *matCellDef="let element">
        <div *ngIf="element.info">
          <span class="badge badge-pill {{element.info.automatic_renovation ? 'badge-success' : 'badge-danger'}}">
            {{element.info.automatic_renovation ? "Sim" : "Não"}}
          </span>
        </div>
        <div *ngIf="!element.info">-</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Ações </th>
      <td mat-cell *matCellDef="let element" class="actions-buttons">
        <button mat-icon-button
                color="primary"
                matTooltip="Perfil do Asaas"
                aria-label="Payments List"
                (click)="openAsaasProfile(element)">
          <mat-icon>account_balance</mat-icon>
        </button>
        <button mat-icon-button
                color="primary"
                matTooltip="Listagem dos pagamentos"
                aria-label="Payments List"
                [routerLink]="['payments', element.id]">
          <mat-icon>payments</mat-icon>
        </button>
        <button mat-icon-button
                color="primary"
                matTooltip="Adiciona um novo plano"
                aria-label="Add"
                (click)="openAddFeaturePlanDialog(element)">
          <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button
                color="primary"
                matTooltip="Edita o plano atual"
                aria-label="Edit"
                (click)="openEditFeaturePlanDialog(element)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button
                color="warn"
                matTooltip="Desabilita o plano atual"
                aria-label="Block"
                (click)="deactivateConfirmDialog(element)"
                [disabled]="!element.info?.active">
          <mat-icon>block</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</mat-card-content>
