import { Component, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import * as _moment from 'moment';

import { Group } from '../../../../shared/models/group.model';
import { SnackBar } from '../../../../shared/utils/snackbar';
import { NewGroupModalComponent } from '../new-group-modal/new-group-modal.component';
import { FeaturePlanModalComponent } from '../feature-plan-modal/feature-plan-modal.component';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { GroupHasFeaturePlanService } from '../../../../core/services/groupHasFeaturePlan.service';

const moment = _moment;

@Component({
  selector: 'app-groups-table',
  templateUrl: './groups-table.component.html',
  styleUrls: ['./groups-table.component.css'],
  providers: [SnackBar]
})
export class GroupsTableComponent implements AfterViewInit {
  @Input() groups: Group[];
  @Input() isActiveGroupsTable: boolean = false;
  @Output() updateGroups: EventEmitter<void> = new EventEmitter<void>();

  displayedColumns: string[] = ['name', 'active', 'test_plan', 'feature_plan_name',
    'feature_plan_end_date', 'automatic_renovation', 'actions'];
  dataSource = new MatTableDataSource<Group>([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private snackBar: SnackBar,
    public dialog: MatDialog,
    private groupHasFeaturePlanService: GroupHasFeaturePlanService,
  ) { }

  ngAfterViewInit(): void {
    this.setDataSource();
  }

  refreshTable(): void {
    this.updateGroups.emit();
    this.setDataSource();
  }

  setDataSource() {
    this.dataSource.data = this.groups;
    this.dataSource.paginator = this.paginator;

    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = ((data, property) =>
      property === 'name' ? data[property].trim().toLowerCase() : data.info?.[property]
    );
    this.setInitialSortState();
  }

  setInitialSortState() {
    const sortState: Sort = {
      active: 'feature_plan_end_date',
      direction: this.isActiveGroupsTable ? 'asc' : 'desc'
    };

    this.sort.active = sortState.active;
    this.sort.direction = sortState.direction;
    this.sort.sortChange.emit(sortState);
  }

  openNewOrgDialog(): void {
    const dialogRef = this.dialog.open(NewGroupModalComponent, {
      width: '600px'
    });
    dialogRef.afterClosed().subscribe(
      resp => { if (resp) { this.refreshTable(); } }
    );
  }

  openAddFeaturePlanDialog(data): void {
    const dialogRef = this.dialog.open(FeaturePlanModalComponent, {
      width: '400px',
      data: {
        groupId: data.id,
        groupName: data.name,
        groupHasFeaturePlan: null
      }
    });
    dialogRef.afterClosed().subscribe(
      resp => { if (resp) { this.refreshTable(); } }
    );
  }

  openEditFeaturePlanDialog(data): void {
    const dialogRef = this.dialog.open(FeaturePlanModalComponent, {
      width: '400px',
      data: {
        groupId: data.id,
        groupName: data.name,
        groupHasFeaturePlan: data.info
      }
    });
    dialogRef.afterClosed().subscribe(
      resp => { if (resp) { this.refreshTable(); } }
    );
  }

  openAsaasProfile(element): void {
    const win = window.open(element.asaas_url, '_blank');
    win.focus();
  }

  deactivateConfirmDialog(data): void {
    const message = `Tem certeza que deseja desativar o plano atual da organização ${data.name}?`;

    const dialogData = new ConfirmDialogModel('Desativar Organização', message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.groupHasFeaturePlanService.deactivate(data.info.id)
          .pipe(first())
          .subscribe(response => {
              this.snackBar.openSnackBar('Plano desativado com sucesso!');
              this.refreshTable();
            },
            error => {
              this.snackBar.openSnackBar('Erro ao desativar plano!', undefined, false);
            });
      }
    });
  }

  filterGroups = (name: string) => {
    this.dataSource.filter = name.trim().toLocaleLowerCase();
  }

  hasFeaturePlanEndDateWarning(group: Group): boolean {
    const warningStartDate = moment().subtract(15, 'days');
    const warningEndDate = moment().add(15, 'days');
    const featurePlanEndDate = moment(group.info?.feature_plan_end_date || '0001-01-01');

    return featurePlanEndDate.isBetween(warningStartDate, warningEndDate, 'days', '[]');
  }

  getTooltipMessage(group: Group): string {
    const today = moment().startOf('day');
    const featurePlanEndDate = moment(group.info?.feature_plan_end_date || '0001-01-01');

    if (featurePlanEndDate.isSame(today, 'days')) {
      return group.info?.active ? 'Este plano vence hoje' : 'Este plano venceu hoje';
    } else if (featurePlanEndDate.isBefore(today, 'days')) {
      return `Este plano venceu há ${(today.diff(featurePlanEndDate, 'days'))} dia(s)`;
    } else {
      return `Este plano vence em ${(featurePlanEndDate.diff(today, 'days'))} dia(s)`;
    }
  }
}
