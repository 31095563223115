import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators, AbstractControl, FormControl, NgForm, FormGroupDirective} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { first } from 'rxjs/operators';
import * as _moment from 'moment';

import { FeaturePlanService } from '../../../../core/services/featurePlan.service';
import { FeaturePlan } from '../../../../shared/models/featurePlan.model';
import { GroupService } from '../../../../core/services/group.service';
import {SnackBar} from '../../../../shared/utils/snackbar';
import { cpfCnpjValidator, featurePlanEndDateValidator } from '../../../../shared/utils/custom-validators';

const moment = _moment;

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-new-group-modal',
  templateUrl: './new-group-modal.component.html',
  styleUrls: ['./new-group-modal.component.css'],
  providers: [SnackBar]
})
export class NewGroupModalComponent implements OnInit {
  groupForm: FormGroup;
  step = 1;
  maxSteps = 3;
  featurePlans: FeaturePlan[];
  emailForm: FormGroup;
  asaasForm: FormGroup;
  matcher = new MyErrorStateMatcher();

  constructor(
    private formBuilder: FormBuilder,
    private featurePlanService: FeaturePlanService,
    private groupService: GroupService,
    private snackBar: SnackBar
  ) { }

  ngOnInit(): void {
    this.loadAllFeaturePlans();
    this.groupForm = this.formBuilder.group({
        featurePlan: [null, Validators.required],
        test: [false, Validators.required],
        price: [0, [this.planConditionallyRequiredValidator, Validators.min(0)]],
        startDate: [moment().startOf('day'), Validators.required],
        endDate: ['', Validators.required],
        paymentInterval: [30, [this.testConditionallyRequiredValidator, Validators.min(1)]],
    }, { validators: featurePlanEndDateValidator });
    this.groupForm.get('test').valueChanges
    .subscribe(value => {
        this.groupForm.get('paymentInterval').updateValueAndValidity();
        this.groupForm.get('price').updateValueAndValidity();

        if (value) {
          this.groupForm.controls.paymentInterval.setValue(1);
          this.groupForm.controls.price.setValue(0);
        } else {
          this.groupForm.controls.paymentInterval.setValue(30);
        }
    });

    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
    this.asaasForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      cpfCnpj: ['', [Validators.minLength(11), Validators.maxLength(14), cpfCnpjValidator]],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      mobilePhone: [''],
      postalCode: [''],
      address: [''],
      addressNumber: [''],
      complement: [''],
      province: [''],
      externalReference: [''],
      notificationDisabled: [false],
      additionalEmails: [''],
      municipalInscription: [''],
      stateInscription: [''],
      observations: [''],
    });
  }

  updatePrice(featurePlanId: number): void {
    const featurePlan = this.featurePlans.find(element => element.id === featurePlanId);
    this.groupForm.controls.price.setValue(featurePlan.price);
  }

  loadAllFeaturePlans(): void {
    this.featurePlanService.getAll()
      .pipe(first())
      .subscribe(featurePlans => this.featurePlans = featurePlans);
  }

  testConditionallyRequiredValidator(formControl: AbstractControl): Validators {
    if (!formControl.parent) {
      return null;
    }

    if (!formControl.parent.get('test').value) {
      return Validators.required(formControl);
    }

    return null;
  }

  planConditionallyRequiredValidator(formControl: AbstractControl): Validators {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get('featurePlan').value &&
        !formControl.parent.get('test').value) {
      return Validators.required(formControl);
    }
    return null;
  }

  get f() { return this.groupForm.controls; }

  nextStep(): void {
    if (this.step < this.maxSteps) {
      this.step += 1;
    }
  }

  previousStep(): void {
    if (this.step > 0) {
      this.step -= 1;
    }
  }

  formIsValid(): boolean {
    if (this.step === 1) {
      return this.asaasForm.valid;
    } else if (this.step === 2) {
      return this.groupForm.valid;
    } else {
      return this.emailForm.valid;
    }
  }

  onSubmit(): void {
    const email = this.emailForm.controls.email.value;
    const data = this.groupForm.getRawValue();
    const dateFormat = 'YYYY-MM-DD';
    data.startDate = data.startDate.format(dateFormat);
    data.endDate = data.endDate.format(dateFormat);
    data.email = email;
    data.asaas = this.removeAsaasSpecialChar(this.asaasForm.getRawValue());
    this.createGroup(data);
  }

  removeAsaasSpecialChar(data): any {
    const regex: RegExp = /\D+/g;
    data.cpfCnpj = data.cpfCnpj.replace(regex, '');
    data.phone = data.phone.replace(regex, '');
    data.mobilePhone = data.mobilePhone.replace(regex, '');
    data.postalCode = data.postalCode.replace(regex, '');
    data.postalCode = data.postalCode.replace(regex, '');
    data.municipalInscription = data.municipalInscription.replace(regex, '');
    data.stateInscription = data.stateInscription.replace(regex, '');
    return data;
  }

  createGroup(data): void {
    this.groupService.createGroup(data)
      .pipe(first())
      .subscribe(response => {
          this.snackBar.openSnackBar('Organização criada com sucesso!');
        },
        error => {
          this.snackBar.openSnackBar('Erro ao criar a organização!', undefined, false);
        });
  }

}
