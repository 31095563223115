import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { Inject } from '@angular/core';
import * as _moment from 'moment';

import { FeaturePlanService } from '../../../../core/services/featurePlan.service';
import { GroupHasFeaturePlanService } from '../../../../core/services/groupHasFeaturePlan.service';
import { SnackBar } from '../../../../shared/utils/snackbar';
import { FeaturePlan } from '../../../../shared/models/featurePlan.model';
import { featurePlanEndDateValidator } from '../../../../shared/utils/custom-validators';

const moment = _moment;

@Component({
  selector: 'app-feature-plan-modal',
  templateUrl: './feature-plan-modal.component.html',
  styleUrls: ['./feature-plan-modal.component.css'],
  providers: [SnackBar]
})
export class FeaturePlanModalComponent implements OnInit {
  groupForm: FormGroup;
  featurePlans: FeaturePlan[];
  dialogTitle: string;
  isEditing: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private featurePlanService: FeaturePlanService,
    private groupHasFeaturePlanService: GroupHasFeaturePlanService,
    private snackBar: SnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.loadAllFeaturePlans();
    const groupHasFeaturePlan = this.data.groupHasFeaturePlan;
    if (groupHasFeaturePlan) {
      this.dialogTitle = `Editar plano na organização ${this.data.groupName}`;
      this.isEditing = true;
    } else {
      this.dialogTitle = `Adicionar plano na organização ${this.data.groupName}`;
      this.isEditing = false;
    }
    this.groupForm = this.formBuilder.group({
      id: [groupHasFeaturePlan ? groupHasFeaturePlan.id : null],
      featurePlan: [groupHasFeaturePlan ? groupHasFeaturePlan.feature_plan_id : null, Validators.required],
      test: [groupHasFeaturePlan ? groupHasFeaturePlan.test_plan : false, Validators.required],
      price: [groupHasFeaturePlan ? groupHasFeaturePlan.price : 0, [this.planConditionallyRequiredValidator, Validators.min(0)]],
      startDate: [groupHasFeaturePlan ? moment(groupHasFeaturePlan.feature_plan_start_date) : moment().startOf('day'), Validators.required],
      endDate: [groupHasFeaturePlan ? moment(groupHasFeaturePlan.feature_plan_end_date) : '', Validators.required],
      paymentInterval: [groupHasFeaturePlan ? groupHasFeaturePlan.feature_plan_payment_interval : 30,
        [this.testConditionallyRequiredValidator, Validators.min(1)]],
    }, { validators: featurePlanEndDateValidator });
    this.groupForm.get('test').valueChanges
      .subscribe(value => {
        this.groupForm.get('paymentInterval').updateValueAndValidity();
        this.groupForm.get('price').updateValueAndValidity();

        if (value) {
          this.groupForm.controls.paymentInterval.setValue(1);
          this.groupForm.controls.price.setValue(0);
        } else {
          this.groupForm.controls.paymentInterval.setValue(30);
        }
      });
  }

  updatePrice(featurePlanId: number): void {
    const featurePlan = this.featurePlans.find(element => element.id === featurePlanId);
    this.groupForm.controls.price.setValue(featurePlan.price);
  }

  loadAllFeaturePlans(): void {
    this.featurePlanService.getAll()
      .pipe(first())
      .subscribe(featurePlans => this.featurePlans = featurePlans);
  }

  testConditionallyRequiredValidator(formControl: AbstractControl): Validators {
    if (!formControl.parent) {
      return null;
    }

    if (!formControl.parent.get('test').value) {
      return Validators.required(formControl);
    }

    return null;
  }

  planConditionallyRequiredValidator(formControl: AbstractControl): Validators {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get('featurePlan').value &&
        !formControl.parent.get('test').value) {
      return Validators.required(formControl);
    }
    return null;
  }

  get f() { return this.groupForm.controls; }

  onSubmit(): void {
    const data = this.groupForm.getRawValue();
    const dateFormat = 'YYYY-MM-DD';
    data.startDate = data.startDate.format(dateFormat);
    data.endDate = data.endDate.format(dateFormat);
    if (this.isEditing) {
      this.editGroupHasFeaturePlan(this.data.groupId, data);
    } else {
      this.createGroupHasFeaturePlan(this.data.groupId, data);
    }
  }

  createGroupHasFeaturePlan(groupId, data): void {
    this.groupHasFeaturePlanService.create(groupId, data)
      .pipe(first())
      .subscribe(response => {
          this.snackBar.openSnackBar('Plano adicionado com sucesso!');
        },
        error => {
          this.snackBar.openSnackBar('Erro ao adicionar plano!', undefined, false);
        });
  }

  editGroupHasFeaturePlan(groupId, data): void {
    this.groupHasFeaturePlanService.edit(groupId, data)
      .pipe(first())
      .subscribe(response => {
          this.snackBar.openSnackBar('Plano editado com sucesso!');
        },
        error => {
          this.snackBar.openSnackBar('Erro ao editar plano!', undefined, false);
        });
  }

}
