<ng-template #step1Content>
  <form [formGroup]="asaasForm">
    <p class="text-muted">Os dados dessa primeira etapa serão enviados para o ASAAS</p>
    <p class="text-muted">Campos com * são obrigatórios</p>
    <div class="row">
      <div class="col-md-7">
        <div class="full-width">
          <mat-form-field>
            <mat-label>Nome*</mat-label>
            <input matInput placeholder="Nome" formControlName="name">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-5">
        <div class="full-width">
          <mat-form-field>
            <mat-label>CPF ou CNPJ</mat-label>
            <input matInput maxlength="14" placeholder="Informe o CPF ou o CNPJ" formControlName="cpfCnpj">
          </mat-form-field>
        </div>
      </div>
    </div>


    <div class="full-width">
      <mat-form-field>
        <mat-label>Email de cobrança*</mat-label>
        <input matInput placeholder="exemple@example.com" formControlName="email">
      </mat-form-field>
    </div>


    <div class="row">
      <div class="col-md-6">
        <div class="full-width">
          <mat-form-field>
            <mat-label>Celular</mat-label>
            <input matInput placeholder="(00) 00000-0000" formControlName="mobilePhone">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="full-width">
          <mat-form-field>
            <mat-label>Telefone</mat-label>
            <input matInput placeholder="(00) 00000-0000" mask="(99) 99999-9999" formControlName="phone">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="full-width">
      <mat-form-field>
        <mat-label>CEP</mat-label>
        <input matInput placeholder="00000-000" formControlName="postalCode">
      </mat-form-field>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="full-width">
          <mat-form-field>
            <mat-label>Rua</mat-label>
            <input matInput placeholder="Informe a rua" formControlName="address">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="full-width">
          <mat-form-field>
            <mat-label>Número</mat-label>
            <input matInput placeholder="Número" formControlName="addressNumber">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <div class="full-width">
          <mat-form-field>
            <mat-label>Complemento</mat-label>
            <input matInput placeholder="Ex.: Portão azul, etc." formControlName="complement">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="full-width">
          <mat-form-field>
            <mat-label>Bairro</mat-label>
            <input matInput placeholder="Infomre o bairro" formControlName="province">
          </mat-form-field>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-6">
        <div class="full-width">
          <mat-form-field>
            <mat-label>Inscrição municipal</mat-label>
            <input matInput placeholder="Inscrição municipal" formControlName="municipalInscription">
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="full-width">
          <mat-form-field>
            <mat-label>Inscrição estadual</mat-label>
            <input matInput placeholder="Inscrição estadual" formControlName="stateInscription">
          </mat-form-field>
        </div>
      </div>
    </div>


    <div class="full-width">
      <mat-form-field>
        <mat-label>Observações</mat-label>
        <input matInput placeholder="Observações" formControlName="observations">
      </mat-form-field>
    </div>

    <div class="full-width">
      <mat-checkbox class="example-margin" formControlName="notificationDisabled" color="primary">
        Desabilitar notificações
      </mat-checkbox>
    </div>
  </form>
</ng-template>

<ng-template #step2Content>
  <form [formGroup]="groupForm">
    <div class="full-width">
      <mat-form-field appearance="standard">
        <mat-label>Planos</mat-label>
        <mat-select formControlName="featurePlan" (selectionChange)="updatePrice($event.value)">
          <mat-option *ngFor="let featurePlan of featurePlans" [value]="featurePlan.id">
            {{ featurePlan.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="full-width">
      <mat-checkbox class="example-margin" formControlName="test" color="primary">
        Ativar periodo de teste
      </mat-checkbox>
    </div>

    <div class="full-width mb-2" *ngIf="f.featurePlan.value && !f.test.value">
      <mat-form-field>
        <mat-label>Preço</mat-label>
        <input matInput type="number" min="0" formControlName="price" [value]="f.featurePlan.price">
        <span matSuffix>BRL</span>
        <mat-hint align="start"><strong>Este é o valor cobrado periodicamente</strong></mat-hint>
      </mat-form-field>
    </div>

    <div class="full-width" *ngIf="!f.test.value">
      <mat-form-field>
        <mat-label>Periodicidade de pagamento</mat-label>
        <input matInput type="number" min="1" formControlName="paymentInterval">
        <span matSuffix>dias</span>
      </mat-form-field>
    </div>

    <div class="full-width">
      <mat-form-field appearance="standard" class="full-width">
        <mat-label>Data de Início</mat-label>
        <input matInput [matDatepicker]="pickerStart" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="full-width">
      <mat-form-field appearance="standard" class="full-width">
        <mat-label>Data de Fim</mat-label>
        <input matInput [matDatepicker]="pickerEnd" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </mat-form-field>
      <small *ngIf="groupForm.invalid && (groupForm.get('endDate').dirty || groupForm.get('endDate').touched)">
        <mat-error *ngIf="groupForm.errors?.featurePlanEndDateError">
          A data de fim do plano deve ser após a data de início
        </mat-error>
      </small>
    </div>
  </form>
</ng-template>

<ng-template #step3Content>
  <form [formGroup]="emailForm">
    <div class="full-width">
      <mat-form-field>
        <mat-label>Email do primeiro usuário administrador</mat-label>
        <input matInput placeholder="exemple@example.com" formControlName="email" [errorStateMatcher]="matcher">
        <mat-error *ngIf="emailForm.controls.email.hasError('email') &&
         !emailForm.controls.email.hasError('required')">
          Email inválido
        </mat-error>
        <mat-error *ngIf="emailForm.controls.email.hasError('required')">
          Email é <strong>obrigatório</strong>
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</ng-template>

<h2 mat-dialog-title>Criar Organização</h2>
<mat-dialog-content class="mat-typography">
  <div [ngSwitch]="step">
    <div *ngSwitchCase="1">
      <ng-container *ngTemplateOutlet="step1Content"></ng-container>
    </div>
    <div *ngSwitchCase="2">
      <ng-container *ngTemplateOutlet="step2Content"></ng-container>
    </div>
    <div *ngSwitchCase="3">
      <ng-container *ngTemplateOutlet="step3Content"></ng-container>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close style="float: left">Cancel</button>
  <button mat-button (click)="previousStep()" *ngIf="step !== 1">Anterior</button>
  <button mat-button (click)="nextStep()" *ngIf="step !== maxSteps" [disabled]="!formIsValid()">
    Próximo
  </button>
  <button mat-button [mat-dialog-close]="true" (click)="onSubmit()" *ngIf="step === maxSteps" [disabled]="!formIsValid()">
    Finalizar
  </button>
</mat-dialog-actions>
