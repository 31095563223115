// tslint:disable:variable-name
export class PaymentList {
  object?: string;
  hasMore: boolean;
  totalCount: number;
  limit: number;
  offset: number;
  data: Payment[];
}

export class Payment {
  object?: string;
  id: number;
  dateCreated: string;
  customer: string;
  subscription: string;
  installment: string;
  dueDate: string;
  value: number;
  netValue: number;
  discount: any;
  interest: any;
  fine: any;
  billingType: string;
  status: string;
  description: string;
  externalReference: string;
  originalDueDate: string;
  originalValue: number;
  interestValue: number;
  confirmedDate: string;
  paymentDate: string;
  clientPaymentDate: string;
  invoiceUrl: string;
  bankSlipUrl: string;
  invoiceNumber: string;
  deleted: boolean;
  postalService: boolean;
  anticipated: boolean;
}

export enum BillingType {
  BOLETO = 'Boleto Bancário',
  CREDIT_CARD = 'Cartão de Crédito',
  UNDEFINED = 'Perguntar ao Cliente'
}

export enum PaymentStatus {
  PENDING = 'Aguardando pagamento',
  CONFIRMED = 'Confirmada',
  RECEIVED = 'Recebida',
  RECEIVED_IN_CASH = 'Recebida em Dinheiro',
  OVERDUE = 'Atrasada',
  REFUND_REQUESTED = 'Estorno Solicitado',
  REFUNDED = 'Estornada',
  CHARGEBACK_REQUESTED = 'Recebido chargeback',
  CHARGEBACK_DISPUTE = 'Em disputa de chargeback',
  AWAITING_CHARGEBACK_REVERSAL = 'Disputa vencida, aguardando repasse da adquirente',
  DUNNING_REQUESTED = 'Em processo de recuperação',
  DUNNING_RECEIVED = 'Recuperada',
  AWAITING_RISK_ANALYSIS = 'Pagamento em análise',
}
