import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { backendUrl } from '../../configs/backend.config';
import {PaymentList} from '../../shared/models/asaas.model';

@Injectable({ providedIn: 'root' })
export class GroupService {
    constructor(private http: HttpClient) { }

    createGroup(data): Observable<any> {
        return this.http.post(`${backendUrl}/group/create_group/`, data);
    }

    getAllGroup(): Observable<any> {
        return this.http.get(`${backendUrl}/group/all_groups/`);
    }

    getGroupPayments(groupId: number, page: number, perPage: number = 10): Observable<PaymentList> {
      return this.http.get<PaymentList>(`${backendUrl}/group/${groupId}/payments/`);
    }
}
