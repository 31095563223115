<div class="page">
  <div class="row">
    <div class="offset-md-1 col-md-10">
      <p class="h6 mb-4">
        <!-- Implement breadcrumb component to generate this path automatically -->
        <a routerLink="">Home</a> / <span>Histórico de Pagamentos</span>
      </p>
    </div>
  </div>
  <div class="row">
    <div class="offset-md-1 col-md-10">
      <div class="row mb-2">
        <strong class="col-md-1">Valor</strong>
        <strong class="col-md-3">Status</strong>
        <strong class="col-md-3">Tipo de Pagamento</strong>
        <strong class="col-md-2">Data de Vencimento</strong>
        <strong class="col-md-3">Ações</strong>
      </div>
    </div>
    <p class="col-md-12 text-center mt-3" *ngIf="payments.length === 0">Nenhum pagamento encontrado</p>
    <mat-card class="offset-md-1 col-md-10 mb-3" *ngFor="let payment of payments">
      <mat-card-content>
        <div class="row align-items-center">
          <div class="col-md-1"> R$ {{ payment.value }} </div>
          <div class="col-md-3">
            <span class="badge badge-pill badge-{{ getStatusClass(payment) }}">
              {{ paymentStatus[payment.status] }}
            </span>
          </div>
          <div class="col-md-3">
            {{ billingType[payment.billingType] }}
          </div>
          <div class="col-md-2">
            {{ payment.originalDueDate|date: 'dd/MM/yyyy' }}
          </div>
          <div class="col-md-3">
            <a mat-icon-button color="primary" aria-label="Boleto" matTooltip="Boleto"
               [href]="payment.bankSlipUrl" target="_blank">
              <mat-icon>request_quote</mat-icon>
            </a>
            <a mat-icon-button color="primary" aria-label="Invoice" matTooltip="Invoice"
               [href]="payment.invoiceUrl" target="_blank">
              <mat-icon>link</mat-icon>
            </a>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="row mb-5" *ngIf="hasMore">
    <div class="col-md-12 text-center">
      <button mat-stroked-button color="primary" (click)="loadPayments()">Ver mais</button>
    </div>
  </div>
</div>
