import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../../shared/models/user.model';
import { TFAToken } from '../../shared/models/auth.model';;
import { backendUrl } from '../../configs/backend.config';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    private tfaTokenSubject: BehaviorSubject<TFAToken>;
    public tfaToken: Observable<TFAToken>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        this.tfaTokenSubject = new BehaviorSubject<TFAToken>(JSON.parse(localStorage.getItem('tfaToken')));
        this.tfaToken = this.tfaTokenSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    public get tfaTokenValue(): TFAToken {
        return this.tfaTokenSubject.value;
    }

    login(username, password): any {
        return this.http.post<any>(`${backendUrl}/api-token-auth/`, { username, password })
            .pipe(map(user => {
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
                return user;
            }));
    }

    twoFactorAuth(token: string): any {
        return this.http.post<any>(`${backendUrl}/totp/login/`, {token})
            .pipe(map(data => {
                localStorage.setItem('tfaToken', JSON.stringify(data));
                this.tfaTokenSubject.next(data);
                return data;
            }));
    }

    hasTwoFactorAuth(): any {
        return this.http.get<any>(`${backendUrl}/totp/login/`)
            .pipe(map(data => {
                return data;
            }));
    }

    getTFAQrCode(): any {
        return this.http.get<any>(`${backendUrl}/totp/create/`)
            .pipe(map(data => {
                return data;
            }));
    }

    logout(): void {
        // remove user from local storage and set current user to null
        localStorage.removeItem('currentUser');
        localStorage.removeItem('tfaToken');
        this.currentUserSubject.next(null);
        this.tfaTokenSubject.next(null);
    }

    isLogged(): boolean {
      return !!(localStorage.getItem('currentUser') && localStorage.getItem('tfaToken'));
    }

    getToken(): string {
      if (this.currentUserValue) {
        return this.currentUserValue.token;
      }
    }

    getTFAToken(): string {
      if (this.tfaTokenValue) {
        return this.tfaTokenValue.deviceToken;
      }
    }
}
