<h2 mat-dialog-title>{{ dialogTitle }}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="groupForm">
    <div class="full-width">
      <mat-form-field appearance="standard">
        <mat-label>Planos</mat-label>
        <mat-select formControlName="featurePlan" (selectionChange)="updatePrice($event.value)">
          <mat-option *ngFor="let featurePlan of featurePlans" [value]="featurePlan.id">
            {{ featurePlan.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="full-width">
      <mat-checkbox class="example-margin" formControlName="test" color="primary">
        Período de teste
      </mat-checkbox>
    </div>

    <div class="full-width mb-2" *ngIf="f.featurePlan.value && !f.test.value">
      <mat-form-field>
        <mat-label>Preço</mat-label>
        <input matInput type="number" min="0" formControlName="price" [value]="f.featurePlan.price">
        <span matSuffix>BRL</span>
        <mat-hint align="start"><strong>Este é o valor cobrado periodicamente</strong></mat-hint>
      </mat-form-field>
    </div>

    <div class="full-width" *ngIf="!f.test.value">
      <mat-form-field>
        <mat-label>Periodicidade de pagamento</mat-label>
        <input matInput type="number" min="1" formControlName="paymentInterval">
        <span matSuffix>dias</span>
      </mat-form-field>
    </div>

    <div class="full-width">
      <mat-form-field appearance="standard" class="full-width">
        <mat-label>Data de Início</mat-label>
        <input matInput [matDatepicker]="pickerStart" formControlName="startDate">
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <mat-datepicker #pickerStart></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="full-width">
      <mat-form-field appearance="standard" class="full-width">
        <mat-label>Data de Fim</mat-label>
        <input matInput [matDatepicker]="pickerEnd" formControlName="endDate">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <mat-datepicker #pickerEnd></mat-datepicker>
      </mat-form-field>
      <small *ngIf="groupForm.invalid && (groupForm.get('endDate').dirty || groupForm.get('endDate').touched)">
        <mat-error *ngIf="groupForm.errors?.featurePlanEndDateError">
          A data de fim do plano deve ser após a data de início
        </mat-error>
      </small>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close style="float: left">Cancel</button>
  <button mat-button [mat-dialog-close]="true" [disabled]="!groupForm.valid" (click)="onSubmit()">Salvar</button>
</mat-dialog-actions>
