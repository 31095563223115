<div class="bg-login">
  <div class="dark-layout"></div>
  <mat-card class="card">
    <mat-card-header>
      <mat-card-title>
        <img class="card-logo" src="../../../assets/images/logo-horizontal-h1-v2-blue.png" alt="Logo DataPolicy">
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <h4>Serviço de Vendas</h4>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" *ngIf="step=='login'">
        <mat-form-field class="full-width">
          <mat-label>Username</mat-label>
          <input matInput placeholder="username" formControlName="username">
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>Senha</mat-label>
          <input matInput type="password" formControlName="password">
        </mat-form-field>

        <div class="btn-container">
          <button mat-raised-button class="full-width" color="primary" type="submit">Próximo</button>
        </div>
      </form>

      <form [formGroup]="tfaForm" (ngSubmit)="onSubmitTFA()" *ngIf="step=='tfa'">
        <p>Utilize o Google Authenticator para gerar o token</p>
        <div class="qr-code-container d-flex justify-content-center" *ngIf="qrCodeURI">
          <img class="qr-code-img" [src]="qrCodeURI" alt="QR Code">
        </div>
        <mat-form-field class="full-width">
          <mat-label>Token</mat-label>
          <input matInput placeholder="token" formControlName="token">
        </mat-form-field>

        <div class="btn-container">
          <button mat-raised-button class="full-width" color="primary" type="submit">Entrar</button>
        </div>
      </form>

    </mat-card-content>
  </mat-card>
</div>
