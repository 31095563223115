import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

import { GroupService } from '../../core/services/group.service';
import { PaymentList, Payment, BillingType, PaymentStatus } from '../../shared/models/asaas.model';
import { SnackBar } from '../../shared/utils/snackbar';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css'],
  providers: [SnackBar]
})
export class PaymentsComponent implements OnInit {
  groupId: number;
  payments: Payment[] = [];
  hasMore = false;
  page = 1;
  billingType = BillingType;
  paymentStatus = PaymentStatus;

  constructor(
    private route: ActivatedRoute,
    private groupService: GroupService,
    private snackBar: SnackBar,
  ) { }

  ngOnInit(): void {
    this.groupId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    this.loadPayments();
  }

  loadPayments(): void {
    this.groupService.getGroupPayments(this.groupId, this.page, 10)
      .pipe(first())
      .subscribe(response => {
          this.payments = this.payments.concat(response.data);
          this.hasMore = response.hasMore;
          if (this.hasMore) {
            this.page += 1;
          }
        },
        error => {
          this.snackBar.openSnackBar('Erro ao carregar pagamentos da organização!', undefined, false);
        });
  }

  getStatusClass(payment: Payment): string {
    const statusClass = {
      PENDING: 'warning',
      CONFIRMED: 'success',
      RECEIVED: 'success',
      OVERDUE: 'danger'
    };
    let result = statusClass[payment.status];
    if (!result) {
      result = 'warning';
    }
    return result;
  }

}
