import { FormControl, ValidationErrors } from '@angular/forms';
import { cpf, cnpj } from 'cpf-cnpj-validator';

export const cpfCnpjValidator = (control: FormControl) => {
  if (!control.value) {
    return null;
  }
  if (!(cpf.isValid(control.value) || cnpj.isValid(control.value))) {
    return 'Cpf or cpnj not valid';
  }
};

export const featurePlanEndDateValidator = (control: FormControl): ValidationErrors | null => {
  if (control.get('endDate').value < control.get('startDate').value) {
    return { 'featurePlanEndDateError': true };
  }
};
