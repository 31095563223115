import { environment } from '../../environments/environment';

let backendUrl;
if (environment.env === 'PRODUCTION') {
  backendUrl = 'https://sales.api.datapolicy.co';
} else if (environment.env === 'DEVELOPMENT') {
  backendUrl = 'https://dev.sales.api.datapolicy.co';
} else {
  backendUrl = 'http://localhost:8001';
}

export {backendUrl};
