import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Material
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
// Flex Layout
import { FlexLayoutModule } from '@angular/flex-layout';
// Others
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxLoadingModule } from 'ngx-loading';
// Components
import { HomeComponent } from './home.component';
import { NewGroupModalComponent } from './components/new-group-modal/new-group-modal.component';
import { GroupsTableComponent } from './components/groups-table/groups-table.component';
import { FeaturePlanModalComponent } from './components/feature-plan-modal/feature-plan-modal.component';
import { AppRoutingModule } from '../../app-routing.module';


@NgModule({
  declarations: [
    HomeComponent,
    NewGroupModalComponent,
    GroupsTableComponent,
    FeaturePlanModalComponent
  ],
  imports: [
    CommonModule,
    // Material
    MatCardModule,
    MatTableModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatSortModule,
    MatExpansionModule,
    // Others
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatPaginatorModule,
    AppRoutingModule,
    NgxLoadingModule,
  ],
  providers: [
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class HomeModule { }
