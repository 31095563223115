<div class="root-container">
  <mat-toolbar color="primary" *ngIf="isLogged()">
    <a class="a-clean ml-2" [routerLink]="['']">
      <img src="../assets/images/logo-horizontal-h1-v2.png" alt="Logo" class="logo-toolbar">
    </a>
    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon" aria-label="Logout" (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </mat-toolbar>
  <router-outlet></router-outlet>
</div>
