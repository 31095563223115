import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBar {
  durationInSeconds = 3;

  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string = 'OK', success: boolean = true): void {
    this.snackBar.open(message, action, {
      duration: this.durationInSeconds * 1000,
      panelClass: success ? 'toast-success' : 'toast-error'
    });
  }

}
