import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

import { AuthenticationService } from '../../core/authentication/authentication.service';
import { SnackBar } from '../../shared/utils/snackbar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [SnackBar]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  tfaForm: FormGroup;
  step = 'login';
  returnUrl: string;
  qrCodeURI: SafeResourceUrl;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private snackBar: SnackBar,
    private sanitizer: DomSanitizer
  ) {
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
        username: ['', Validators.required],
        password: ['', Validators.required]
    });
    this.tfaForm = this.formBuilder.group({
        token: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit(): void {
    if (this.loginForm.invalid) {
        return;
    }

    this.authenticationService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
          data => {
            this.snackBar.openSnackBar('Login realizado com sucesso!');
            this.verifyTwoFactorAuth();
            // set loading, if necessary
            // this.router.navigate([this.returnUrl]);
          },
          error => {
            this.snackBar.openSnackBar('Usuário ou senha inválido!', undefined, false);
          });
    }

    verifyTwoFactorAuth(): void {
      this.authenticationService.hasTwoFactorAuth()
        .pipe(first())
        .subscribe(
          hasTFA => {
            this.step = 'tfa';
            console.log(hasTFA);
            if (hasTFA) {
              return;
            } else {
              this.loadTFAQrCode();
            }
          },
          error => {
            this.snackBar.openSnackBar('Problema no servidor, tente novamente mais tarde!',
              undefined, false);
          });
    }

    loadTFAQrCode(): void {
      this.authenticationService.getTFAQrCode()
        .pipe(first())
        .subscribe(
          data => {
            this.qrCodeURI = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
                 + data.img_base64);
          },
          error => {
            this.snackBar.openSnackBar('Problema no servidor, tente novamente mais tarde!',
              undefined, false);
          });
    }

    onSubmitTFA(): void {
      if (this.tfaForm.invalid) {
        return;
      }
      this.authenticationService.twoFactorAuth(this.tfaForm.controls.token.value)
      .pipe(first())
      .subscribe(
          data => {
            this.snackBar.openSnackBar('Token verificado com sucesso!');
            this.router.navigate([this.returnUrl]);
          },
          error => {
            this.snackBar.openSnackBar('Token inválido!', undefined, false);
          });
    }

  forgotPassword(): void {
  }

}
