import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FeaturePlan } from '../../shared/models/featurePlan.model';
import { backendUrl } from '../../configs/backend.config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FeaturePlanService {
    constructor(private http: HttpClient) { }

    getAll(): Observable<FeaturePlan[]> {
      return this.http.get<FeaturePlan[]>(`${backendUrl}/feature/all_feature_plans/`);
    }
}
