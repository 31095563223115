import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Material Design
import { MatCardModule } from '@angular/material/card';
// Pages
import { PaymentsComponent } from './payments.component';
// Others
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    PaymentsComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    ScrollingModule,
    MatTooltipModule,
    RouterModule,
  ],
})
export class PaymentsModule { }
