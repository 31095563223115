import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { GroupHasFeaturePlan } from '../../shared/models/featurePlan.model';
import { backendUrl } from '../../configs/backend.config';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GroupHasFeaturePlanService {
    constructor(private http: HttpClient) { }

    create(groupId, data): Observable<GroupHasFeaturePlan> {
      return this.http.post<GroupHasFeaturePlan>(`${backendUrl}/group/${groupId}/feature_plan/`, data);
    }

    edit(groupId, data): Observable<GroupHasFeaturePlan> {
      return this.http.put<GroupHasFeaturePlan>(`${backendUrl}/group/${groupId}/feature_plan/`, data);
    }

    deactivate(id): Observable<GroupHasFeaturePlan> {
      return this.http.post<GroupHasFeaturePlan>(`${backendUrl}/group_has_feature_plan/${id}/deactivate/`, {});
    }
}
