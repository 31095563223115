<div class="page">
  <div class="flex-column">
    <ngx-loading [show]="isLoading"></ngx-loading>

    <div class="active-groups" *ngIf="activeGroups.length > 0">
      <mat-card class="offset-md-1 col-md-10">
        <app-groups-table [groups]="activeGroups"
                          (updateGroups)="getAllGroups()"
                          [isActiveGroupsTable]="true">
        </app-groups-table>
      </mat-card>
    </div>

    <div class="inactive-groups" *ngIf="inactiveGroups.length > 0">
      <mat-card class="offset-md-1 col-md-10">
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title> Organizações com planos inativos há mais de 15 dias </mat-panel-title>
          </mat-expansion-panel-header>

          <app-groups-table [groups]="inactiveGroups"
                            (updateGroups)="getAllGroups()">
          </app-groups-table>
        </mat-expansion-panel>
      </mat-card>
    </div>
  </div>
</div>
