import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import * as _moment from 'moment';

import { SnackBar } from '../../shared/utils/snackbar';
import { Group } from '../../shared/models/group.model';
import { GroupService } from '../../core/services/group.service';

const moment = _moment;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [SnackBar],
})
export class HomeComponent implements OnInit {
  activeGroups: Group[] = [];
  inactiveGroups: Group[] = [];
  isLoading = false;

  constructor(private groupService: GroupService, private snackBar: SnackBar) { }

  ngOnInit(): void {
    this.getAllGroups();
  }

  getAllGroups(): void {
    const activeGroupsTableMinDate = moment().subtract(15, 'days').format('YYYY-MM-DD');

    this.activeGroups.splice(0, this.activeGroups.length);
    this.inactiveGroups.splice(0, this.inactiveGroups.length);
    this.isLoading = true;

    this.groupService.getAllGroup()
      .pipe(first())
      .subscribe(response => {
          response.forEach(group => {
            if (group.info?.feature_plan_end_date >= activeGroupsTableMinDate) {
              this.activeGroups.push(group);
            } else {
              this.inactiveGroups.push(group);
            }
          });
          this.isLoading = false;
        },
        error => {
          this.isLoading = false;
          this.snackBar.openSnackBar('Erro ao carregar organizações!', undefined, false);
        });
  }

}
