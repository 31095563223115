import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { DATE_FORMATS } from './shared/utils/format-datepicker';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import { LoginModule } from './modules/login/login.module';
import { HomeModule } from './modules/home/home.module';
import { PaymentsModule } from './modules/payments/payments.module';
import { ConfirmDialogComponent } from './shared/components/confirm-dialog/confirm-dialog.component';


@NgModule({
    declarations: [
      AppComponent,
      ConfirmDialogComponent,
    ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      HttpClientModule,
      MatToolbarModule,
      MatIconModule,
      MatSnackBarModule,
      MatButtonModule,
      MatDialogModule,
      // Pages modules
      LoginModule,
      HomeModule,
      PaymentsModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]},
        {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
